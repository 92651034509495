.react-calendar-heatmap {
  .color-empty {
    fill: #ebedf0;
  }

  .color-scale-1 {
    fill: #85bae6;
  }

  .color-scale-2 {
    fill: #658fc6;
  }

  .color-scale-3 {
    fill: #4068a3;
  }

  .color-scale-4 {
    fill: #1e2568;
  }
}
