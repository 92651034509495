body {
  margin: 0;
  font-family: 'CircularStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "CircularStdMedium";
  src: local('CircularStd-Medium'), url(./assets/fonts/CircularStd-Medium.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStdMedium";
  src: local('CircularStd-MediumItalic'), url(./assets/fonts/CircularStd-MediumItalic.otf) format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src: local('CircularStd-Bold'), url(./assets/fonts/CircularStd-Bold.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "CircularStd";
  src: local('CircularStd-BoldItalic'), url(./assets/fonts/CircularStd-BoldItalic.otf) format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "CircularStd";
  src: local('CircularStd-Black'), url(./assets/fonts/CircularStd-Black.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Frock";
  src: local('Frock'), url(./assets/fonts/Frock.otf) format('opentype');
}
