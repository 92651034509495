.date-slider {
  width: 100%;

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.2em;
    aspect-ratio: 1/1;
  }

  @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) {
      .swiper-slide {
        height: calc(min(4.2em, 14vw));
      }
    }
}

  .swiper-slide-active {
    border-radius: 50%;
    color: white;
    background: black;
    transition: background 0.5s ease-out;
  }
}
