.reaction-chip.MuiChip-root {
  .MuiTypography-root {
    font-size: small;
  }

  &.MuiChip-filled {
    background: black;

    .MuiTypography-root {
      color: white;
    }
  }

  .MuiChip-label {
    margin-left: 0.3em;
    margin-right: 0.3em;
  }

  .MuiChip-icon {
    margin-left: 0.6em !important;
  }
}
