.edit-profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em 1.5em 0 1.5em;

  .avatar {
    width: 20vh;
    height: 20vh;
    cursor: pointer;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-input {
    display: none;
  }

  .avatar-camera-badge {
    width: 1.5em;
    height: 1.5em;
    cursor: pointer;
  }
}
