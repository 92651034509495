.user-challenge-stats {
  padding-left: 1em;

  .divider {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  .MuiTypography-h6 {
    font-style: italic;
  }

  .stats-description-label {
    opacity: 0.6;
    font-style: italic;
  }

  .stats-value-label {
    font-weight: bolder;
  }

  .big-label {
    font-size: xxx-large;
  }

  .recurring-days-checkboxes {
    overflow-x: auto;
  }
}
