a {
  color: black;
}

.App {
  display: flex !important;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.App-content-container {
  flex: 1;
  overflow-y: auto;
}

.column-container {
  height: 100%;
}
